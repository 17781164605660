<script setup lang="ts">
interface IAvaliableLanguages {
  message: string
  link: string
}

import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import BaseInput from '@/components/forms/BaseInput.vue'
import feedbackService, { type IFeedbackRequest } from '@/services/feedback.service'
import { useField, useForm } from 'vee-validate'
import { ref, computed, onMounted, watch } from 'vue'
import { string } from 'yup'

const languages: ILanguage[] = [...languagesItems].sort((a: ILanguage, b: ILanguage) =>
  a.displayName > b.displayName ? 1 : -1
)

const httpErrorTimeOut = ref<NodeJS.Timeout | number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<NodeJS.Timeout | number | null>(null)
const httpSuccess = ref<string>('')

const availableLanguages = ref<IAvaliableLanguages[]>([])

const { errors, handleSubmit, resetForm } = useForm()

const { value: searchTerm, errorMessage: searchTermError } = useField(
  'searchTerm',
  string().required()
)

const dynamicId = computed(() => {
  return `${Math.floor(Math.random() * 1000)}`
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})
/**
 * Get links based on keywords
 * @param keywords - keywords to search
 * @returns - list of links
 */
const getLinks = (keywords: string): ILanguage[] => {
  const links = languages.filter((o: ILanguage) => {
    return (
      // remove extra spaces and convert to lowercase
      o.language === keywords.toLowerCase().trim() ||
      o.language === keywords.toLowerCase().trim().replace(/ /g, '') ||
      o.searchTerm.split(' ').filter((i: string) => {
        return i === keywords.toLowerCase()
      }).length > 0
    )
  })
  return links
}

const onSubmit = handleSubmit(async (values) => {
  httpSuccess.value = ''
  httpError.value = ''
  availableLanguages.value = []

  const links = getLinks(values.searchTerm)
  if (links.length > 0) {
    links.forEach((link) => {
      availableLanguages.value.push({
        message: `${link.displayName} already exists at `,
        link: `https://www.jdoodle.com${link.link}`
      })
    })
    return
  }

  const requestPayload: IFeedbackRequest = {
    comment: values.searchTerm,
    isSurvey: true
  }

  await feedbackService
    .sendFeedback(requestPayload)
    .then(() => {
      httpSuccess.value = 'Thank you for your input!'
      httpError.value = ''
      resetForm()
    })
    .catch((error) => {
      httpSuccess.value = ''
      httpError.value = error?.response?.data?.message
    })
})
onMounted(() => {
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>
<template>
  <form @submit.prevent="onSubmit">
    <div class="flex flex-col gap-3 sm:flex-row">
      <div class="w-full md:w-[70%]">
        <BaseInput
          :id="dynamicId"
          label="Which language would you like to see next in JDoodle?"
          :inputType="'input'"
          placeholder="New Language"
          :error="searchTermError"
          v-model="searchTerm"
        />
      </div>
      <button
        :disabled="!canSubmit"
        class="btn btn-primary h-[45px] w-full sm:mt-[63px] sm:w-[200px]"
        type="submit"
      >
        Post Language
      </button>
    </div>
    <div>
      <p :class="['p-xsmall ', 'error', { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <p :class="['p-xsmall ', 'success', { hidden: !httpSuccess }]">
        {{ httpSuccess }}
      </p>
      <p
        v-for="(availableLanguage, index) of availableLanguages"
        :key="index"
        class="p-xsmall success"
      >
        {{ availableLanguage.message }}
        <span class="link-secondary">
          <a :href="availableLanguage.link" target="_blank">
            {{ availableLanguage.link }}
          </a>
        </span>
      </p>
    </div>
  </form>
</template>
