<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { useUtilModelsStore } from '@/stores/util.models.store'
import utilModelsService from '@/services/util.models.service'
import Button from '@/components/atoms/button/index.vue'
import Logo from '@/components/utils/LogoComp.vue'

const hs = 'hs-alert'

const utilModelsStore = useUtilModelsStore()

const alertTimeOut = computed(() => {
  return utilModelsStore.alertTimeOut
})
const title = computed(() => {
  return utilModelsStore.isAlertTitle
})
const subTitle = computed(() => {
  return utilModelsStore.isAlertSubtitle
})
const returnHS = computed(() => {
  return utilModelsStore.isAlertReturnHs
})
/**
 * Closes the modal
 */
const okay = () => {
  if (alertTimeOut.value) {
    clearTimeout(alertTimeOut.value as number)
    utilModelsStore.alertTimeOut = null
  }
  if (returnHS.value) utilModelsService.openModal(returnHS.value)
  else utilModelsService.closeModal(hs)
}

onMounted(() => {
  watch(alertTimeOut, () => {
    if (alertTimeOut.value && alertTimeOut.value == 'new') {
      utilModelsService.openModal(hs)
      utilModelsStore.alertTimeOut = setTimeout(() => {
        okay()
      }, 3000)
    }
  })
})
</script>

<template>
  <div
    :id="hs"
    class="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden backdrop-blur-lg backdrop-brightness-50 [--overlay-backdrop:static]"
  >
    <div
      class="m-3 mt-0 opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg"
    >
      <div class="section-primary flex flex-col rounded-xl shadow-lg">
        <div class="flex items-center justify-start px-4 py-3">
          <Logo :isLink="false" />
        </div>
        <FontAwesomeIcon icon="fa-triangle-exclamation" class="rounded-full text-right text-6xl" />
        <!-- title -->
        <div class="flex w-full flex-col items-center justify-center p-4 sm:p-7">
          <h1 class="heading-xsmall mb-2 block text-center">
            {{ title }}
          </h1>
          <p v-if="subTitle" class="p-small text-center">
            {{ subTitle }}
          </p>

          <Button variant="filled" size="medium" class="my-2 w-52" @click="okay"> Okay </Button>
        </div>
      </div>
    </div>
  </div>
</template>
