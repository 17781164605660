<script setup lang="ts">
import { computed } from 'vue'
import InfoTriangle from '@/assets/images/ide/info-triangle.svg?component'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { cn } from '@/utils/cn'

const authStore = useAuthStore()
const organisationStore = useOrganisationStore()

const projectCount = computed(() => authStore.projectLimit!.projectCount)
const projectLimit = computed(() => authStore.projectLimit!.limit)
const limitCrossed = computed(
  () => authStore.isCreateProjectLimitReached || authStore.isCreateProjectLimitExceeded
)
/**
 * Owner does not include Admin, Teacher, or Student role
 */
const isOwner = computed(() => organisationStore.isOrganisationOwner)

const warningTitle = computed(() => {
  if (!limitCrossed.value) {
    return 'You are nearing the end of project limit'
  } else {
    return 'You have run out of project limit'
  }
})

const warningDesc = computed(() => {
  return `Users on this plan can create and save up to ${projectLimit.value} projects.`
})
</script>

<template>
  <section class="surface-primary-header flex rounded-lg p-2">
    <div class="mt-1">
      <InfoTriangle :class="cn('icon-ide-default w-10', limitCrossed && 'text-error-500')" />
    </div>
    <div class="flex-grow px-2">
      <header class="flex flex-col justify-between gap-x-2 sm:flex-row sm:items-center">
        <h3 id="warning-heading" class="my-1 font-bold">
          {{ warningTitle }}
        </h3>
        <p :class="cn('my-1 text-right text-sm font-semibold', limitCrossed && 'text-error-500')">
          {{ projectCount }}/ {{ projectLimit }} projects created
        </p>
      </header>
      <p class="my-1 text-sm">
        {{ warningDesc }}
        To create more projects
        <router-link v-if="isOwner" :to="{ path: 'pricing' }" class="font-semibold underline">
          upgrade your plan
        </router-link>
        <span v-else class="font-semibold underline">contact your admin</span>
      </p>
    </div>
  </section>
</template>
