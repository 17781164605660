<script setup lang="ts">
import { HOMEVIEWMODELS } from '@/utils/models'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { computed, ref } from 'vue'
import { RouterLink } from 'vue-router'

interface IFilterItem {
  id: string
  title: string
}

const searchTerm = ref<string>('')
const selectedFilter = ref<string>('popular')

const languages: ILanguage[] = languagesItems.sort((a: ILanguage, b: ILanguage) =>
  a.displayName > b.displayName ? 1 : -1
)

/**
 * @param filter  - filter by first letter of language name
 */
const onSelectedFilter = (filter: string): void => {
  selectedFilter.value = filter
  searchTerm.value = ''
}

const filterItems: IFilterItem[] = [
  {
    id: 'popular',
    title: 'Popular'
  },
  {
    id: 'A-E',
    title: 'A-E'
  },
  {
    id: 'F-K',
    title: 'F-K'
  },
  {
    id: 'L-Q',
    title: 'L-Q'
  },
  {
    id: 'R-Z',
    title: 'R-Z'
  },
  {
    id: 'terminal',
    title: 'Terminal'
  }
]

const links = computed<ILanguage[]>(() => {
  if (searchTerm.value) {
    let links = languages.filter((o: ILanguage) => {
      return o.searchTerm.includes(searchTerm.value.toLowerCase())
    })
    return links
  } else if (selectedFilter.value === 'popular' || selectedFilter.value === 'terminal') {
    return [...languages]
      .filter((e: ILanguage) => e[selectedFilter.value as keyof ILanguage])
      .sort((a: ILanguage, b: ILanguage) => {
        return a.displayName > b.displayName ? 1 : -1
      })
  } else if (selectedFilter.value === '') {
    return languages
      .slice()
      .sort((a: ILanguage, b: ILanguage) => (a.displayName > b.displayName ? 1 : -1))
  } else {
    return languages
      .filter((item: ILanguage) => {
        const firstChar = item.displayName.charAt(0).toUpperCase()
        return firstChar >= selectedFilter.value[0] && firstChar <= selectedFilter.value[2]
      })
      .sort((a: ILanguage, b: ILanguage) => (a.displayName > b.displayName ? 1 : -1))
  }
})
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center gap-10">
    <div class="relative w-full">
      <input
        name="languageSearch"
        class="section-primary p-small block w-full rounded-md border px-10 py-3"
        v-model="searchTerm"
      />
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <FontAwesomeIcon icon="fa-magnifying-glass" class="h-5 w-5" />
      </div>
      <button
        v-if="searchTerm"
        @click="searchTerm = ''"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <FontAwesomeIcon icon="fa-circle-xmark" class="h-5 w-5" />
      </button>
    </div>
    <div class="flex w-full items-center justify-between gap-2 border-b px-4 py-2">
      <button
        v-for="(item, index) in filterItems"
        :key="index"
        :class="[
          'w-fit',
          'font-semibold',
          {
            'text-btn-secondary': selectedFilter === item.id
          }
        ]"
        @click="onSelectedFilter(item.id)"
      >
        {{ item.title }}
      </button>
    </div>
    <div>
      <div class="flex max-h-48 flex-wrap justify-between gap-4 overflow-auto">
        <template v-for="(item, index) in links" :key="index">
          <router-link :to="item.link" :data-hs-overlay="`#${HOMEVIEWMODELS.ONLINECOMPILER}`">
            <span class="block w-full px-4 py-2 hover:text-btn-primary-hover">{{
              item.displayName
            }}</span>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
