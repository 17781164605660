<script setup lang="ts">
import { computed } from 'vue'
import { useApiStore } from '@/stores/api.store'
import ToastAlertIcon from '@/assets/images/svg/toast-alert.svg?component'

const msg = computed(() => {
  return useApiStore().globalMsg
})
/**
 * Closes the toast
 */
const closeToast = () => {
  useApiStore().setGlobalMsg('')
}
</script>

<template>
  <div
    v-if="msg"
    class="stroke-primary-default text-mid background-primary fixed right-4 top-4 z-[70] max-w-[22rem] rounded-xl border shadow"
    role="alert"
  >
    <div class="flex w-full flex-col">
      <button @click="closeToast" class="absolute right-0 m-2">
        <svg
          class="h-4 w-4 text-gray-400 dark:text-gray-300"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
      <div class="flex px-5 py-4">
        <div class="flex-shrink-0">
          <ToastAlertIcon class="h-6 w-6" />
        </div>
        <div class="ml-4">
          <p class="text-sm text-gray-700 dark:text-gray-400">
            {{ msg }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
