<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useField } from 'vee-validate'
import { useApiStore } from '@/stores/api.store'

import { ChatStatus, type IChat, AIType } from '@/utils/jDroid'
import { type IMeta } from '@/utils/meta'

import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import { IDEVIEWMODELS, CONFIRM } from '@/utils/models'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'

import utilModelsService from '@/services/util.models.service'
import ideService from '@/services/ide/ide.service'
import jdroidService from '@/services/ide/jdroid.service'
import settingService from '@/services/ide/settings/setting.service'
import projectsService from '@/services/ide/projects.service'
import liveCodingService from '@/services/ide/liveCoding.service'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { useProjectManager } from '@/stores/projectManager.store'
import { useLiveCodeStore } from '@/stores/liveCode.store'
import { NavigationFailureType, isNavigationFailure } from 'vue-router'

import Button from '@/components/atoms/button/index.vue'
import BaseSelect from '@/components/forms/BaseSelect.vue'
import ProjectLimitWarning from '@/components/ide/ide/restrictions/ProjectLimitWarning.vue'

import { cloneDeep } from 'lodash-es'
import { string } from 'yup'

import jdroidSmallStar from '@/assets/images/ide/ai/jdroid-small-star-1.svg'
import generate from '@/assets/images/ide/refresh.svg'

interface IProjectSetup {
  id: string
  label: string
  desc: string
}

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const organisationStore = useOrganisationStore()
const ideStore = useIdeStore()
const jdroidStore = useJdroidStore()
const projectManagerStore = useProjectManager()
const liveCodeStore = useLiveCodeStore()

const allLanguages: ILanguage[] = cloneDeep(languagesItems)
const languages: ILanguage[] = cloneDeep(languagesItems)
  .filter((langauge: ILanguage) => {
    return !langauge.isAdvanced
  })
  .sort((a: ILanguage, b: ILanguage) => (a.displayName > b.displayName ? 1 : -1))

const projectSetupOptions: IProjectSetup[] = [
  {
    id: 'new-project-start-with-example-button-ide',
    label: 'Start with an Example',
    desc: 'Get, set, code!'
  },
  {
    id: 'new-project-jdroid-ai-button-ide',
    label: 'Use JDroid AI',
    desc: 'Generate code with a prompt'
  },
  {
    id: 'new-project-start-with-empty-ide',
    label: 'Empty Project',
    desc: 'Build code from scratch'
  }
]

const searchTerm = ref<string>('')
const showSuggestions = ref<boolean>(false)
const selectedLanguage = ref<ILanguage | null>(null)
const icon = ref<string | null>(null)
const iconDark = ref<string | null>(null)
const versionIndex = ref<string | null>(null)
const isMultifile = ref<boolean>(false)
const prompt = ref<string>('')
const projectSetup = ref<string>('')

const {
  value: projectName,
  errorMessage: projectNameError,
  setErrors
} = useField<string>('projectName', string().required())

const isDefaultIde = computed(() => {
  return ideStore.isDefaultIde
})
const isIdeView = computed(() => {
  return ideStore.isIdeView
})

const links = computed(() => {
  if (searchTerm.value !== '') {
    return languages.filter((lang: ILanguage) => {
      return lang.searchTerm.includes(searchTerm.value.toLowerCase())
    })
  } else {
    return projectsService.groupFrameWorkLanguage(languages)
  }
})

const showDropdown = computed<boolean>(() => {
  return (
    (searchTerm.value !== '' && searchTerm.value !== selectedLanguage.value?.displayName) ||
    showSuggestions.value
  )
})

const versionData = computed(() => {
  if (isMultifile.value === true) {
    const selectedVersionLanguage = allLanguages.find((langauge: ILanguage) => {
      return langauge.language == selectedLanguage.value?.language && langauge.isAdvanced
    })
    if (selectedVersionLanguage) {
      return cloneDeep(selectedVersionLanguage.versions)
    } else {
      return []
    }
  } else {
    return cloneDeep(selectedLanguage.value?.versions) || []
  }
})
const showVersions = computed<boolean>(() => {
  if (!Array.isArray(versionData.value)) {
    return false
  }
  return versionData.value?.length > 0
})
const showMultifile = computed<boolean>(() => {
  const hasAny = allLanguages.findIndex((langauge: ILanguage) => {
    return (
      langauge.language == selectedLanguage.value?.language &&
      langauge.isAdvanced &&
      !langauge.advanceCompiler &&
      !langauge.advanceCompilerDatabase
    )
  })
  if (hasAny !== -1) return true
  return false
})
const isJdroidSupported = computed<boolean>(() => {
  return (
    selectedLanguage.value?.language !== 'blockly' &&
    selectedLanguage.value?.language !== 'html' &&
    !selectedLanguage.value?.advanceCompiler &&
    !selectedLanguage.value?.advanceCompilerDatabase
  )
})
const filtedProjectSetupOptions = computed<IProjectSetup[]>(() => {
  if (isJdroidSupported.value) {
    if (selectedLanguage.value?.advanceCompilerDatabase) {
      return projectSetupOptions.filter((project) => project.label !== 'Start with an Example')
    }
    return projectSetupOptions
  } else {
    if (
      selectedLanguage.value?.advanceCompilerDatabase ||
      selectedLanguage.value?.advanceCompiler
    ) {
      return projectSetupOptions.filter(
        (project) => project.label !== 'Empty Project' && project.label !== 'Use JDroid AI'
      )
    }

    return projectSetupOptions.filter((project) => project.label !== 'Use JDroid AI')
  }
})
const isJdroidProjectSetupOptionSelected = computed<boolean>(() => {
  return projectSetup.value === 'Use JDroid AI'
})
const canSubmit = computed(() => {
  return (
    selectedLanguage.value !== null &&
    selectedLanguage.value?.displayName !== '' &&
    (isJdroidProjectSetupOptionSelected.value ? prompt.value !== '' : true) &&
    !projectNameError.value &&
    !authStore.isCreateProjectLimitReached &&
    !authStore.isCreateProjectLimitExceeded
  )
})
const defaultLanguage = computed<ILanguage | null>(() => jdroidStore.startCodingLanguage)

const isProjectLimitCloseOrReachedOrExceeded = computed(() => {
  return (
    authStore.isCreateProjectLimitClose ||
    authStore.isCreateProjectLimitReached ||
    authStore.isCreateProjectLimitExceeded
  )
})

/**
 * Reset the versions
 */
const resetVersions = () => {
  if (selectedLanguage.value) {
    if (showVersions.value == true && versionData.value && versionData.value?.length > 0) {
      versionIndex.value = versionData.value[versionData.value.length - 1]
    } else {
      versionIndex.value = null
    }
  } else {
    versionIndex.value = null
  }
}
/**
 * Handle the click of the language
 * @param langauge The language to start coding in
 */
const handleClick = (langauge: ILanguage) => {
  if (!langauge.categoryText) {
    selectedLanguage.value = langauge
    searchTerm.value = langauge.displayName
  }
}

/**
 * Handle the jdroid data and call the jdroid API
 * @param chat - input object of jdroid
 */
const startFromJdroid = async (chat: IChat) => {
  jdroidService.clearChat()
  if (chat) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    jdroidService.addChat(chat)
  }
}

/**
 * Go to the selected IDE
 * @param link The link to go to
 * @param chat The chat to go to
 */
const goToIdePush = async (link: string, chat: IChat | null) => {
  jdroidStore.setStartCodingLanguage(null)
  selectedLanguage.value = null
  searchTerm.value = ''
  prompt.value = ''

  jdroidStore.setStartCoadingVersion(versionIndex.value)
  if (projectSetup.value === 'Empty Project') {
    jdroidStore.setStartCoadingEmpty(true)
  } else {
    jdroidStore.setStartCoadingEmpty(false)
  }

  const failure = await router.push(link)

  if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
    const confirmModal: Object = {
      title: 'Hold on! Project in progress...',
      subtitle: 'Your unsaved changes will be lost.',
      retunHs: null,
      yesBtnText: 'Start Fresh',
      noBtnText: 'Go Back'
    }
    ideStore.isCodeUpdated &&
      utilModelsService.confirmPromise(...Object.values(confirmModal)).then(async () => {
        await utilModelsService.closeModalAsync(CONFIRM.CONFIRM)
        startFromJdroid(chat as IChat)
        ideService.initOnRouterChange(route.meta as IMeta)
      })
    if (!ideStore.isCodeUpdated) {
      startFromJdroid(chat as IChat)
      ideService.initOnRouterChange(route.meta as IMeta)
    }
  } else {
    startFromJdroid(chat as IChat)
  }
}
/**
 * Go to the selected IDE
 * @param chat The chat to go to
 */
const goToIde = async (chat: IChat | null = null) => {
  utilModelsService.closeModal(IDEVIEWMODELS.STARTCODING)
  ideStore.setShowStartCodingModal(false)
  let link = ''
  if (isMultifile.value == true) {
    link = allLanguages.find((langauge: ILanguage) => {
      return langauge.language == selectedLanguage.value?.language && langauge.isAdvanced
    })?.link as string
  } else {
    link = selectedLanguage.value?.link as string
  }
  goToIdePush(link, chat)
}
/**
 * Submit the form
 */
const onSubmit = async () => {
  if (liveCodeStore.isLiveCodingActive) {
    liveCodingService.disconnectLiveCoding()
  }
  const isAdvancedCompilerDatabaseRoute = selectedLanguage.value?.advanceCompilerDatabase
  if (isAdvancedCompilerDatabaseRoute) {
    projectManagerStore.setGeneratedProjectName('')
    projectName.value = ''
  } else if (authStore.isUserloggedIn) {
    try {
      await projectsService.checkForDubplicateProjectName(projectName.value)
      projectManagerStore.setGeneratedProjectName(projectName.value)
      projectName.value = ''
      projectManagerStore.setComingFromStartCodingModal(true)
    } catch (err: any) {
      useApiStore().setGlobalMsg('')
      setErrors(`file name ${projectName.value} already exists.`)
      return
    }
  } else {
    projectManagerStore.setGeneratedProjectName(projectName.value)
    projectName.value = ''
  }

  if (isJdroidSupported.value && projectSetup.value === 'Use JDroid AI') {
    const chat: IChat = {
      type: AIType.CHAT,
      autoReplaceEditor: true,
      ideContent: null,
      outputContent: null,
      prompt: prompt.value,
      header: 'Genarate Code',
      status: ChatStatus.LOADING,
      response: null
    }
    goToIde(chat)
  } else {
    goToIde()
  }
}

/**
 * Close the modal
 */
const onClose = async () => {
  if (ideStore.isDefaultIde) {
    if (router.options.history.state.prevPage) {
      router.go(-1)
    } else {
      if (authStore.isUserloggedIn) {
        router.push(`/dashboard`)
      } else {
        router.push('/')
      }
    }
  } else {
    selectedLanguage.value = null
    searchTerm.value = ''

    utilModelsService.closeModal(IDEVIEWMODELS.STARTCODING)
  }
  ideStore.setShowStartCodingModal(false)
  jdroidStore.setStartCodingLanguage(null)
}

/**
 * Open the start coding modal
 * @param language The language to start coding in
 */
const open = async (language: ILanguage | null) => {
  if (language) {
    selectedLanguage.value = language || null
    searchTerm.value = language?.displayName || ''
    icon.value = language.icon
    iconDark.value = language.darkIcon ?? ''
  }
  if (ideStore.showProjectCodingModal) {
    ideStore.setShowProjectCodingModal(false)
    await new Promise((resolve) => setTimeout(resolve, 10))
    settingService.openPopup(IDEVIEWMODELS.MYPROJECTS, true)
    utilModelsService.closeModal(IDEVIEWMODELS.STARTCODING)
  } else {
    await new Promise((resolve) => setTimeout(resolve, 10))
    utilModelsService.openModal(IDEVIEWMODELS.STARTCODING)
    ideStore.setShowStartCodingModal(true)
  }
}

/**
 * Fetches IDE settings if user is logged in and settings are not completed
 */
const getIdeSettings = async () => {
  if (ideStore.isIdeView) return
  if (authStore.isInitiated && authStore.isUserloggedIn) {
    if (organisationStore.isIndividualAccount) {
      if (authStore.isPremium) {
        if (ideStore.ideSettings === null) await ideService.initIdeSettings()
      }
    } else if (organisationStore.isOrganisationOwner || organisationStore.isOrganisationAdmin) {
      if (
        organisationStore.instituteSubscriptionInfo?.plan &&
        organisationStore.instituteSubscriptionInfo?.plan !== PLAN_ENUM.FREE
      ) {
        if (ideStore.ideSettings === null) await ideService.initIdeSettings()
      }
    } else if (organisationStore.isPremiumIde) {
      if (ideStore.ideSettings === null) await ideService.initIdeSettings()
    }
  }
}

/**
 * @param event Click event
 * If clicking outside of dropdown div, dropdown will disappear
 */
const clickEventHandler = (event: any) => {
  if (event.target.id != 'choose-language-input-swo') {
    if (showSuggestions.value) {
      if (event.target.id != 'choose-language-dropdown-swo') {
        showSuggestions.value = false
      } else {
        showSuggestions.value = true
      }
    }
  }
}

/**
 * @description - To open the project manager modal and closing the Project modal
 */
const toggleProjectManagerModal = async () => {
  settingService.openPopup(IDEVIEWMODELS.MYPROJECTS, true)
  utilModelsService.closeModal(IDEVIEWMODELS.STARTCODING)
}

/**
 * @description - To generate the project name
 * @param clickGenerate - generate new name
 */
const generateProjectName = (clickGenerate: boolean = false) => {
  if (!projectName.value || projectName.value === '' || clickGenerate) {
    const generatedName = projectsService.generateProjectName()
    projectName.value = generatedName
  }
}

watch(
  () => selectedLanguage.value,
  () => {
    icon.value = selectedLanguage.value?.icon as string
    iconDark.value = selectedLanguage.value?.darkIcon as string

    isMultifile.value = false
    resetVersions()
    projectSetup.value = filtedProjectSetupOptions.value[0].label

    generateProjectName()
  }
)

watch(
  () => isDefaultIde.value,
  (val) => {
    if (val) generateProjectName()
  }
)
watch(
  () => isIdeView.value,
  (val) => {
    if (val) generateProjectName()
  }
)
watch(
  () => isMultifile.value,
  () => {
    resetVersions()
  }
)
watch(
  () => searchTerm.value,
  () => {
    if (searchTerm.value.length === 0) {
      icon.value = null
      iconDark.value = null
      selectedLanguage.value = null
    }
  }
)
watch(
  () => defaultLanguage.value,
  () => {
    if (defaultLanguage.value !== null && !ideStore.isDefaultIde) {
      open(defaultLanguage.value)
    }
  }
)

onMounted(() => {
  isMultifile.value = false
  versionIndex.value = null
  projectSetup.value = projectSetupOptions[0].label

  getIdeSettings()

  watch(
    () => organisationStore.isIndividualAccount,
    () => {
      if (authStore.isUserloggedIn && organisationStore.isIndividualAccount) {
        // ideService.clearIdeSettings()
        getIdeSettings()
      }
    }
  )
  watch(
    () => organisationStore.instituteSubscriptionInfo?.plan,
    () => {
      if (authStore.isUserloggedIn) {
        // ideService.clearIdeSettings()
        getIdeSettings()
      }
    }
  )
  watch(
    () => ideStore.isIdeView,
    () => {
      if (!ideStore.isIdeView) {
        ideService.clearIdeSettings()
        getIdeSettings()
      }
    }
  )
})

onMounted(() => {
  document.addEventListener('click', clickEventHandler)
})

onUnmounted(() => {
  document.removeEventListener('click', clickEventHandler)
})

defineExpose({
  open
})
</script>

<template>
  <div
    :id="IDEVIEWMODELS.STARTCODING"
    :data-hs-overlay-keyboard="false"
    class="hs-overlay fixed left-0 top-0 z-[60] hidden h-screen w-full overflow-x-auto overflow-y-auto [--overlay-backdrop:false]"
    :class="{
      'bg-gray-900 bg-opacity-70 backdrop-blur-sm dark:bg-opacity-40': isDefaultIde,
      'bg-gray-900 bg-opacity-40 backdrop-blur-sm dark:bg-opacity-40': !isDefaultIde
    }"
  >
    <div
      class="flex min-h-screen w-full justify-center py-2 pl-3 pr-2 transition-all ease-out hs-overlay-open:duration-500 sm:p-8 lg:p-32"
    >
      <div class="mx-auto mb-16 h-auto w-full max-w-3xl lg:mb-0">
        <div class="border-primary flex flex-col rounded-md border">
          <!-- START Header -->
          <div
            class="border-primary flex items-center justify-between rounded-t-md border-b bg-new-secondary-100/90 px-4 py-1 dark:bg-new-neutral-700/80"
          >
            <h1 class="w-full text-center text-xl font-bold leading-loose">Create a new Project</h1>
            <div class="relative flex h-fit max-w-60 items-center">
              <!-- close button -->
              <button
                @click="onClose"
                type="button"
                class="link-primary hs-collapse-toggle transition-all"
              >
                <span class="sr-only">Close</span>
                <FontAwesomeIcon icon="fa-xmark" class="text-primary hs-collapse-open:block" />
              </button>
            </div>
          </div>
          <!-- END Header -->

          <!-- main content -->
          <form
            @submit.prevent="onSubmit"
            class="surface-primary-base stroke-primary-default rounded-b-lg pt-6"
          >
            <!-- START Language and Disclose Row -->
            <div class="flex w-full flex-col">
              <!-- END Language and Disclose Row -->
              <h4
                class="text-high px-5 font-medium leading-loose"
                :class="selectedLanguage ? 'text-sm' : 'text-lg'"
              >
                Pick a language/framework
                <!-- Select from one of our
                <span class="font-bold">{{ getLanguageCount() }} Programming Languages</span>
                and choose the one that's right for you. -->
              </h4>
              <div class="px-4 py-2">
                <div class="relative mb-5">
                  <!-- Dark -->
                  <img
                    v-if="iconDark && iconDark.length > 0"
                    :src="iconDark"
                    alt="language logo"
                    class="absolute left-2 top-3 mr-2 hidden h-8 w-8 p-1 dark:inline-block"
                  />
                  <!-- Light -->
                  <img
                    v-if="icon && icon.length > 0"
                    :src="icon"
                    alt="language logo"
                    class="absolute left-2 top-1 mr-2 inline-block h-8 w-8 p-1"
                  />
                  <input
                    id="choose-language-input-swo"
                    autocomplete="off"
                    v-model="searchTerm"
                    class="background-primary stroke-primary-default dark:focus-text-white placeholder:text-mid text-mid block w-full rounded-md border font-medium outline-none"
                    :class="[
                      { 'h-11 pl-12 pr-2 sm:px-12': selectedLanguage || searchTerm.length > 0 },
                      {
                        'h-14 px-5': !selectedLanguage || searchTerm.length === 0
                      }
                    ]"
                    placeholder="Search IDE Compiler or Terminal"
                    @click="
                      selectedLanguage === null || searchTerm === ''
                        ? (showSuggestions = !showSuggestions)
                        : null
                    "
                  />

                  <div
                    id="choose-language-dropdown-swo"
                    v-if="showDropdown"
                    class="background-primary stroke-primary-default absolute z-50 mt-[1px] w-full rounded-md border p-3"
                  >
                    <div class="max-h-60 overflow-y-auto md:grid">
                      <button
                        v-for="(item, indx) in links"
                        :key="indx"
                        class="w-full"
                        @click="handleClick(item as ILanguage)"
                      >
                        <div class="text-low w-full truncate pt-2 text-left text-sm font-medium">
                          {{ item.categoryText }}
                        </div>
                        <div
                          v-if="!item.categoryText"
                          class="hover:background-secondary hover:text-high text-mid flex items-center justify-start rounded-lg px-2 py-3"
                        >
                          <!-- Dark -->
                          <img
                            :src="(item as ILanguage).darkIcon ? (item as ILanguage).darkIcon : (item as ILanguage).icon"
                            :alt="(item as ILanguage).displayName"
                            class="me-2 hidden h-8 w-8 dark:inline-block"
                          />
                          <!-- Light -->
                          <img
                            v-if="(item as ILanguage).icon"
                            :src="(item as ILanguage).icon"
                            :alt="(item as ILanguage).displayName"
                            class="me-2 inline-block h-8 w-8 dark:hidden"
                          />
                          <span class="inline-block text-sm">{{
                            (item as ILanguage).displayName
                          }}</span>

                          <!-- Empty message -->
                        </div>
                      </button>
                      <p v-if="links.length == 0" class="w-full text-sm">No results found</p>
                    </div>
                  </div>
                </div>
                <!--  when langauge is selected -->
                <div v-if="selectedLanguage">
                  <div v-if="!selectedLanguage.advanceCompilerDatabase" class="relative">
                    <img
                      :src="generate"
                      class="absolute z-10 h-5 w-5 cursor-pointer"
                      :class="!projectNameError ? 'bottom-3 right-3' : 'bottom-8 right-8'"
                      @click="generateProjectName(true)"
                    />
                    <div class="flex flex-col gap-2">
                      <div class="flex w-full justify-between">
                        <div class="text-high w-full px-1 text-sm">
                          Project name<br class="sm:hidden" />
                          <span class="whitespace-nowrap text-sm font-light sm:pl-1"
                            >(Auto generated)</span
                          >
                        </div>
                        <span
                          class="text-low flex w-full items-end justify-end text-right text-sm font-light sm:items-start"
                          >Projects are auto-saved!</span
                        >
                      </div>
                      <input
                        placeholder="My Project"
                        v-model="projectName"
                        :error="projectNameError"
                        class="background-primary stroke-primary-default text-mid flex w-full rounded-md border p-3 text-sm"
                      />
                    </div>
                  </div>
                  <div class="flex flex-grow flex-col gap-3 pt-3 sm:flex-row sm:items-center">
                    <BaseSelect
                      v-if="showVersions"
                      label="Choose language version:"
                      :list="versionData || []"
                      v-model="versionIndex"
                      :isSmallLabel="true"
                      :isBoldLabel="true"
                      :isLightGray="false"
                      customClass="h-12 mb-2 text-high background-primary stroke-primary-default text-mid"
                      class="w-full"
                      labelClass="pl-1 "
                    />
                    <div
                      v-if="showMultifile"
                      :class="[
                        'py-3',
                        {
                          'sm:ml-8 ': showVersions
                        }
                      ]"
                    >
                      <label class="p-small pl-1 pt-2 font-medium">Project Type:</label>
                      <div class="flex gap-2 text-nowrap pt-1">
                        <!-- @input="checkBeforeEdit" -->
                        <label
                          for="singleFile"
                          class="p-xsmall stroke-primary-default flex w-full cursor-pointer justify-center gap-2 rounded border-2 px-4 py-3"
                          :class="[
                            {
                              'stroke-primary-default border': !isMultifile
                            }
                          ]"
                        >
                          <input
                            type="radio"
                            id="singleFile"
                            v-model="isMultifile"
                            :value="false"
                            class="custom-radio h-4 w-4"
                          />
                          <div class="custom-radio-wrapper"></div>
                          <span class="text-high">Single-file</span>
                        </label>

                        <label
                          class="p-xsmall stroke-primary-default flex w-full cursor-pointer justify-center gap-2 rounded border-2 px-4 py-3"
                          :class="[
                            {
                              'stroke-primary-default border': isMultifile
                            }
                          ]"
                        >
                          <input
                            type="radio"
                            id="multiFile"
                            v-model="isMultifile"
                            :value="true"
                            class="custom-radio h-4 w-4"
                          />
                          <div class="custom-radio-wrapper"></div>
                          <span for="multiFile" class="text-high">Multi-file</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <label for="projectSetupOptions" class="p-small pb-4 font-medium"
                      >How would you like to configure your project?</label
                    >
                    <div class="mt-2 flex flex-col gap-4 md:flex-row">
                      <div
                        v-for="(project, index) in filtedProjectSetupOptions"
                        :key="index"
                        class="relative flex w-full gap-4"
                      >
                        <label
                          :for="project.id"
                          class="p-xsmall flex w-full flex-grow cursor-pointer items-center gap-2 rounded border px-4 py-5"
                          :class="[
                            {
                              ' stroke-primary-emphasized  border ': projectSetup === project.label
                            },
                            {
                              'stroke-primary-default  border ': projectSetup !== project.label
                            }
                          ]"
                        >
                          <!-- @input="checkBeforeEdit" -->
                          <input
                            type="radio"
                            :id="project.id"
                            v-model="projectSetup"
                            :value="project.label"
                            class="custom-radio h-4 w-4"
                          />
                          <div class="custom-radio-wrapper"></div>
                          <div class="relative flex flex-col">
                            <div :for="project.label" class="p-small font-bold">
                              {{ project.label }}
                              <span
                                v-if="project.label === 'Use JDroid AI'"
                                class="p-xsmall float-end rounded-full bg-success-400 px-1 font-normal text-black"
                                >New</span
                              >
                            </div>
                            <span class="text-mid pt-1 text-xs">{{ project.desc }}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-1 flex flex-col items-center justify-center rounded-b-md"
                :class="[
                  {
                    ' ': selectedLanguage
                  }
                ]"
              >
                <div
                  v-if="selectedLanguage && isJdroidProjectSetupOptionSelected"
                  class="mt-4 w-full px-4 py-2"
                >
                  <div class="relative w-full">
                    <textarea
                      id="new-project-jdroid-user-input"
                      class="background-primary stroke-primary-default block min-h-9 w-full rounded-md border px-7 pt-2 text-xs font-medium outline-none"
                      :class="[
                        {
                          'focus-text-white bg-[#4F4F4F] placeholder:text-gray-200':
                            !ideStore?.isIdeView && ideStore?.colorMode === 'dark'
                        },
                        {
                          'focus-text-black placeholder:text-low bg-white':
                            ideStore?.isIdeView && ideStore.colorMode !== 'dark'
                        }
                      ]"
                      v-model="prompt"
                      rows="8"
                      placeholder="What can I build for you ?"
                    />
                    <img
                      :src="jdroidSmallStar"
                      class="absolute left-2 top-2 h-4 w-4"
                      alt="JDroid"
                    />
                  </div>
                </div>

                <ProjectLimitWarning
                  v-if="selectedLanguage && isProjectLimitCloseOrReachedOrExceeded"
                  class="mx-4 my-2"
                />

                <!-- section-quaternary -->
                <div class="flex w-full flex-grow flex-wrap justify-between px-4 sm:flex-nowrap">
                  <div
                    class="flex w-full cursor-pointer items-center justify-center gap-2 text-[#5F7394] sm:w-fit"
                    @click="toggleProjectManagerModal"
                  >
                    <FontAwesomeIcon icon="fa-folder-open" class="h-4 w-4" />
                    <span class="text-sm font-medium underline">Open my project </span>
                  </div>
                  <Button
                    :disabled="!canSubmit"
                    variant="filled"
                    class="my-4 w-full px-10 py-3 sm:w-fit"
                  >
                    <FontAwesomeIcon class="pr-2" icon="fa-folder-plus" /> Start Coding
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.custom-radio {
  position: absolute;
  opacity: 0;
}

.custom-radio-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1.5px solid #9ca3af;
  border-radius: 50%;

  transition: all 0.3s ease;
}

.custom-radio:checked + .custom-radio-wrapper {
  accent-color: #9ca3af;
}

.custom-radio-wrapper::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #9ca3af;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.custom-radio:checked + .custom-radio-wrapper::after {
  transform: scale(1);
}
</style>
