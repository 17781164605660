<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted, watch } from 'vue'
import { RouterView } from 'vue-router'
import { IDERELETIVEHEIGHT } from '@/utils/ide'
import { type ILanguage } from '@/utils/sharedData/languages'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import { useNavStore } from '@/stores/nav.store'
import { initAxios } from '@/services/api.service'
import authService from '@/services/auth.service'
import { getAssociationsAndInstituteOwn, getSubscriptionInfo } from '@/services/teach.service'
import { useSSGStore } from '@/stores/ssg.store'
import { useIdeStore } from '@/stores/ide.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { setActiveAccount } from '@/services/organisation.service'
import { useAssessmentStore } from '@/stores/assessment.store'
import { useSubscribeStore } from '@/stores/subscribe.store'

import type IAssociation from '@/components/organisation/interface/IAssociation'

import CookieBanner from '@/components/footer/CookieBanner.vue'
import FooterMarketing from '@/components/footer/FooterMarketing.vue'
import FooterProduct from '@/components/footer/FooterProduct.vue'
import FeedbackModel from '@/components/footer/FeedbackModel.vue'
import ForgotPasswordModel from '@/components/header/auth/ForgotPasswordModel.vue'
import LoginModel from '@/components/header/auth/LoginModel.vue'
import OtpModel from '@/components/header/auth/OtpAuthModel.vue'
import ResetPassword from '@/components/header/auth/ResetPasswordModel.vue'
import RegisterModel from '@/components/header/auth/RegisterModel.vue'
import OtpVerifiedSuccess from '@/components/header/auth/OtpVerifiedSuccessModel.vue'
import ChangePasswordModel from '@/components/header/auth/profile/ChangePasswordModel.vue'
import ChangeUserNameModel from '@/components/header/auth/profile/ChangeUserNameModel.vue'
import OnboardingModel from '@/components/header/auth/OnboardingFlow/OnboardingModel.vue'
import InfoModel from '@/components/utils/InfoModel.vue'
import ConfirmModel from '@/components/utils/ConfirmModel.vue'
import AlertModel from '@/components/utils/AlertModel.vue'
import StartCodingModel from '@/components/ide/ide/ai/launchPage/StartCodingModal.vue'
import PlatformPaymentModal from '@/components/ide/ide/settings/PlatformPaymentModal.vue'
import NavigationMenu from '@/components/header/navigation/NavigationMenu.vue'
import CompilerModel from '@/components/home/__CompilerModel.vue'
import GlobalToast from '@/components/shared/GlobalToast.vue'

import LoadingView from '@/views/LoadingView.vue'

import WebinarHeader from '@/components/webinar/WebinarHeader.vue'
import InviteAcceptModel from '@/components/header/auth/InviteAcceptModel.vue'

import MobileNavigation from '@/components/header/navigation/MobileNavigation.vue'
import MobileProfileTab from '@/components/header/navigation/MobileProfileTab.vue'

const route = useRoute()
const router = useRouter()
const ssgStore = useSSGStore()
const authStore = useAuthStore()
const assessmentStore = useAssessmentStore()
const ideStore = useIdeStore()
const jdroidStore = useJdroidStore()
const organisationStore = useOrganisationStore()
const navStore = useNavStore()
const subscribeStore = useSubscribeStore()

const startCodingModelRef = ref<any | null>(null)
const sticky = ref(true)
const firstLoad = ref<boolean>(true)

const initAndLogedIn = computed(() => authStore.isInitiated && authStore.isUserloggedIn)

const currentOrgCode = computed(() => {
  return organisationStore.associations?.find(
    (org: IAssociation) => org.accountId == organisationStore.activeAccountId
  )?.institute.code
})

const currentOrgName = computed(() => {
  return organisationStore.associations?.find(
    (org: IAssociation) => org.accountId == organisationStore.activeAccountId
  )?.institute.name
})

const isMarketingPage = computed(() => {
  return navStore.isMarketingPage
})

const isOwner = computed(() => {
  return useOrganisationStore().isOwner
})

const isPaymentView = computed(() => {
  return subscribeStore.isPaymentView
})

/**
 * Gets all associations and roles, and sets current account (personal)
 */
async function getAndSetRoles_AndSetActiveAccount() {
  const res = await getAssociationsAndInstituteOwn()
  organisationStore.setAssociationAndInstituteOwn(res)

  if (organisationStore.activeAccountId) {
    organisationStore.getAndSetCurrentAssociation(currentOrgCode.value)
    organisationStore.setOrgCode(currentOrgCode.value!)
    organisationStore.setOrgName(currentOrgName.value as string)

    const roles = await setActiveAccount(
      organisationStore.activeAccountId,
      firstLoad.value ? false : true
    )
    organisationStore.setactiveAccountRoles(roles)
  }

  if (
    organisationStore.isOrganisationOwner ||
    organisationStore.isOrganisationAdmin ||
    organisationStore.isEmbedAdmin ||
    organisationStore.isEmbedViewer ||
    organisationStore.isApiAdmin ||
    organisationStore.isInstituteAdmin ||
    organisationStore.isTeacher ||
    organisationStore.isIndividualAccount
  ) {
    const subscriptionRes = await getSubscriptionInfo()
    organisationStore.setSubscriptionInfo(subscriptionRes)
    assessmentStore.setSubscriptionInfo(subscriptionRes)
  }
}

/**
 * Opens the start coding model
 */
const startCoding = () => {
  const language: ILanguage | null = jdroidStore.startCodingLanguage
  jdroidStore.setStartCodingLanguage(null)
  startCodingModelRef.value?.open(language)
}

onBeforeMount(async () => {
  initAxios()
  await authService.initAuth().then(() => {
    const paths = route.fullPath.split('/')
    let advanceCompilerLangauge: string = ''

    if (route.meta.advanceCompiler) {
      advanceCompilerLangauge = route.meta.language as string
    }

    if (
      paths[1] &&
      (paths[1] == 'dashboard' ||
        paths[1] == 'teams' ||
        paths[1] == 'assessments' ||
        paths[1] == 'student-assessments' ||
        paths[1] == 'subscribe-api' ||
        paths[1] == 'embed-manager' ||
        paths[1] == 'profile' ||
        paths[1] == advanceCompilerLangauge)
    ) {
      const emailQueryPresent = route.query.email || null

      if (!useAuthStore().isUserloggedIn && !emailQueryPresent) {
        router.push({ name: 'login-to-continue' })
      } else if (!useAuthStore().isUserloggedIn && emailQueryPresent) {
        const decodedEmail = decodeURI(emailQueryPresent as string)
        authStore.setEmailFromInvitationMail(decodedEmail)
        router.push({ name: 'signup-to-continue' })
      } else if (useAuthStore().isUserloggedIn && emailQueryPresent) {
        router.push({ name: 'home' })
      }
    }
  })
})

onMounted(async () => {
  if (authStore.isUserloggedIn) {
    //Get invitations for notifications
    await getAndSetRoles_AndSetActiveAccount()
  }

  // Show/hide sticky navbar on top when scrolling
  var oldScrollY = window.scrollY
  window.onscroll = function () {
    if (oldScrollY < window.scrollY) {
      sticky.value = false
    } else {
      sticky.value = true
    }
    oldScrollY = window.scrollY
  }
})

watch(
  () => [authStore.firstName],
  async () => {
    await getAndSetRoles_AndSetActiveAccount()
  }
)

watch(
  () => organisationStore.currentOrgCode,
  () => {
    organisationStore.activeAccountRoles = null
  }
)

watch(
  () => subscribeStore.getMoreCreditsUpdated,
  async () => {
    if (subscribeStore.getMoreCreditsUpdated && isOwner.value) {
      subscribeStore.setIsFetchingSubscriptionInfo(true)
      try {
        const res = await getSubscriptionInfo()
        organisationStore.setSubscriptionInfo(res)
        assessmentStore.setSubscriptionInfo(res)
        jdroidStore.setMoreCreditsUpdated(true)
      } catch (error) {
        subscribeStore.setIsFetchingSubscriptionInfo(false)
      }
      subscribeStore.setIsFetchingSubscriptionInfo(false)
    }
  }
)
</script>

<template>
  <!-- Global notifications -->
  <template v-if="!ssgStore.isSSG">
    <GlobalToast />
    <GlobalToast />
    <!-- Loading view -->
    <LoadingView />
    <!-- Models when not logged in -->
    <LoginModel />
    <!-- Models for onboarding flow -->
    <OnboardingModel />

    <InviteAcceptModel />
    <div v-if="!initAndLogedIn">
      <RegisterModel />
      <ForgotPasswordModel />
      <OtpModel />
      <ResetPassword />
      <OtpVerifiedSuccess />
    </div>
    <!-- Models when logged in -->
    <div v-else>
      <ChangePasswordModel />
      <ChangeUserNameModel />
    </div>

    <!-- Other models -->
    <FeedbackModel />
    <CompilerModel />
    <InfoModel />
    <ConfirmModel />
    <AlertModel />
    <StartCodingModel ref="startCodingModelRef" />
    <PlatformPaymentModal v-if="!isPaymentView" />
  </template>
  <div class="flex min-h-screen flex-col justify-between">
    <!-- <header
      class="sticky left-0 right-0 top-0 z-50 transform drop-shadow-md transition-all duration-300"
      :class="sticky ? 'sm:translate-y-0' : 'sm:-translate-y-full'"
    > -->
    <header :id="IDERELETIVEHEIGHT.EXCLUDEID" class="sticky top-0 z-[60] sm:z-50">
      <!-- This double nav solves preline's dropdown bug when switching from mobile to desktop -->

      <NavigationMenu
        v-if="!ideStore.isIdeView"
        class="block h-full w-full sm:hidden"
        id="mobileNav"
        :isMobileNav="!isMarketingPage ? true : false"
      />

      <NavigationMenu
        v-if="!ideStore.isIdeView || !ideStore.currentMobileTab"
        class="hidden sm:block"
        :is-ide-mobile-nav="ideStore.isIdeView ? true : false"
        id="desktopNav"
      />

      <WebinarHeader v-if="navStore.showWebinarBanner" />
    </header>

    <section class="relative flex-1">
      <div
        v-if="navStore.showMobileNav && ideStore.currentMobileTab != null && !ideStore.isIdeView"
        class="absolute top-0 z-50 h-full"
      >
        <MobileNavigation />
      </div>
      <div
        v-if="
          navStore.showMobileProfileNav && ideStore.currentMobileTab != null && !ideStore.isIdeView
        "
        class="absolute right-0 z-50 h-full"
      >
        <MobileProfileTab />
      </div>
      <RouterView @startCoding="startCoding" />
    </section>

    <CookieBanner />
    <FooterMarketing v-if="!ideStore.isIdeView && isMarketingPage" />
    <FooterProduct v-if="!ideStore.isIdeView && !isMarketingPage" />
  </div>
</template>
