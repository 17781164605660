import onboardingVector1 from '@/assets/images/svg/AuthOnboarding/teacherOBBanner1.svg'
import onboardingVector2 from '@/assets/images/svg/AuthOnboarding/teacherOBBanner2.svg'
import onboardingVector3 from '@/assets/images/svg/AuthOnboarding/teacherOBBanner3.svg'
import onboardingVector4 from '@/assets/images/svg/AuthOnboarding/teacherOBBanner5.svg'

export const OnboardingBannerConstant = [
  {
    title: 'Unlimited AI credits',
    description: 'Generate, debug, and optimize code with JDroid AI.',
    path: onboardingVector1
  },
  {
    title: '2x CPU & faster execution',
    description: 'Get higher output limit with swift execution.',
    path: onboardingVector2
  },
  {
    title: 'IDE customization',
    description: 'Change themes and set default language & code.',
    path: onboardingVector3
  },
  {
    title: '10 Premium Embeds',
    description: 'Embed customizable IDEs in your platform.',
    path: onboardingVector4
  }
]
