<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { cn } from '@/utils/cn'
import { useUtilModelsStore } from '@/stores/util.models.store'
import utilModelsService from '@/services/util.models.service'
import Button from '@/components/atoms/button/index.vue'

const hs = 'hs-confirm'
const utilModelsStore = useUtilModelsStore()

const promise = computed(() => {
  return utilModelsStore.isConfirmPromise
})
const isConfirmPromiseFinished = computed(() => {
  return utilModelsStore.isConfirmPromiseFinish
})
const title = computed(() => {
  return utilModelsStore.isConfirmTitle
})
const subTitle = computed(() => {
  return utilModelsStore.isConfirmSubtitle
})
const returnHS = computed(() => {
  return utilModelsStore.isConfirmReturnHs
})
const yesBtnText = computed(() => {
  return utilModelsStore.isConfirmYesBtnText
})
const noBtnText = computed(() => {
  return utilModelsStore.isConfirmNoBtnText
})
const hideCloseButton = computed(() => {
  return utilModelsStore.isCloseButtonHide
})
const yesBtnClass = computed(() => {
  return utilModelsStore.isConfirmYesBtnClass
})
const noBtnClass = computed(() => {
  return utilModelsStore.isConfirmNoBtnClass
})

/**
 * Closes the modal and resolves the promise
 */
const yes = async () => {
  await utilModelsService.closeModalAsync(hs)
  utilModelsService.confirmeResolve()
  if (returnHS.value) utilModelsService.openModal(returnHS.value)
}
/**
 * Closes the modal and rejects the promise
 */
const no = () => {
  utilModelsService.confirmeReject()
  if (returnHS.value) utilModelsService.openModal(returnHS.value)
}

onMounted(() => {
  watch(promise, (val) => {
    if (!isConfirmPromiseFinished.value && val) {
      utilModelsService.openModal(hs)
    }
  })
})
</script>

<template>
  <div
    :id="hs"
    class="hs-overlay fixed left-0 top-0 z-[65] hidden h-full w-full overflow-y-auto overflow-x-hidden backdrop-blur-lg backdrop-brightness-50 [--overlay-backdrop:static]"
  >
    <div
      class="m-3 mt-0 opacity-0 transition-all ease-out hs-overlay-open:mt-48 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg"
    >
      <div class="section-quaternary flex flex-col rounded-md shadow-lg">
        <div
          class="section-quaternary flex items-center justify-between rounded-t-md border-b border-border-primary px-4 py-2 dark:border-border-primary-dark dark:bg-[#16171a]"
        >
          <h1 class="text-md inline-block font-medium text-black dark:text-white">{{ title }}</h1>
          <div v-if="!hideCloseButton" class="relative flex h-fit max-w-60 items-center">
            <!-- close button -->
            <button
              type="button"
              class="link-primary hs-collapse-toggle transition-all"
              :data-hs-overlay="`#${hs}`"
              @click="no"
            >
              <span class="sr-only">Close</span>
              <FontAwesomeIcon icon="fa-xmark" class="text-primary hs-collapse-open:block" />
            </button>
          </div>
        </div>
        <div class="p-4 sm:p-7">
          <p v-if="subTitle" class="p-small text-center" v-html="subTitle"></p>
          <div class="mt-8 flex flex-wrap justify-center gap-4">
            <Button
              color="secondary"
              variant="outline"
              size="small"
              v-if="yesBtnText"
              :class="cn('px-6', yesBtnClass)"
              @click="yes"
            >
              {{ yesBtnText }}
            </Button>
            <Button
              color="secondary"
              variant="filled"
              size="small"
              v-if="noBtnText"
              :class="cn('px-6', noBtnClass)"
              :data-hs-overlay="`#${hs}`"
              @click="no"
            >
              {{ noBtnText }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
