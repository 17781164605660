<script setup lang="ts">
const props = defineProps({
  bannerCard: {
    type: Object,
    required: true,
    default: null
  },
  cardStyling: {
    type: String,
    required: false,
    default: 'p-6'
  }
})
</script>

<template>
  <div
    class="inline-flex h-[132px] w-[442px] flex-col items-start justify-start gap-2.5 rounded-lg bg-[#D0DCF0] backdrop-blur-[8.80px]"
    :class="props?.cardStyling"
  >
    <div class="inline-flex items-center justify-start gap-4">
      <div class="relative h-[92px] w-[92px]">
        <div class="absolute left-[12.56px] top-[19.25px] h-[54px] w-[67.50px]">
          <img class="absolute left-0 top-0 h-[54px] w-[67.50px]" :src="props.bannerCard.path" />
          <div class="absolute left-[16.88px] top-[22.65px] h-[20.37px] w-[33.75px]"></div>
        </div>
      </div>
      <div class="props.bannerCards-start inline-flex w-[274px] flex-col justify-start gap-2.5">
        <div class="text-lg font-semibold leading-[27px]">
          {{ props.bannerCard.title }}
        </div>
        <div class="w-[238px] text-base font-normal leading-normal">
          {{ props.bannerCard.description }}
        </div>
      </div>
    </div>
  </div>
</template>
