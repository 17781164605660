<script setup lang="ts">
import closeIcon from '@/assets/images/svg/AuthOnboarding/CloseButton.svg'
import { IDEVIEWMODELS } from '@/utils/models'
import { APICONSTANT } from '@/utils/api'
import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import { OnboardingBannerConstant } from '@/components/header/auth/OnboardingFlow/constants/onboarding.constant'

import ModalLayoutAuth from '@/layouts/ModelLayoutAuth.vue'
import PaymentMethod from '@/components/api-plan/PaymentMethod.vue'
import OnboardingCard from '@/components/header/auth/AuthOnboardingAdvBanner/OnboardingCard.vue'
import { useOrganisationStore } from '@/stores/organisation.store'
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const isOwner = computed(() => {
  return useOrganisationStore().isOwner
})
</script>

<template>
  <ModalLayoutAuth :hs="IDEVIEWMODELS.JDROIDGETMORECREDITS">
    <div class="flex max-h-full min-h-screen w-full">
      <div class="absolute right-0 flex justify-end">
        <img
          :src="closeIcon"
          alt="Close"
          class="link-primary hs-collapse-toggle mr-5 mt-2 h-[20px] w-[20px] cursor-pointer transition-all"
          :data-hs-overlay="`#${IDEVIEWMODELS.JDROIDGETMORECREDITS}`"
        />
      </div>
      <div class="flex w-full gap-6 p-3">
        <div
          class="mb-2 hidden w-1/2 lg:flex lg:items-center lg:justify-center"
          :class="`bg-[url('@/assets/images/header/AIBackground.svg')] bg-cover  bg-no-repeat`"
        >
          <div class="flex flex-col py-4">
            <span class="heading-small mb-4 flex w-full justify-start text-black">
              With JDoodle Pro, you’ll get :</span
            >
            <div class="flex flex-1 flex-col gap-3">
              <div
                class="flex w-full cursor-pointer flex-col items-start justify-center"
                v-for="(item, index) in OnboardingBannerConstant"
                :key="index"
              >
                <OnboardingCard
                  :bannerCard="item"
                  :cardStyling="'xl:h-[120px] w-[250px] h-[100px] xl:p-6 p-1'"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center px-4 sm:w-full lg:w-1/2 lg:px-0 dark:text-background-quinary-dark"
        >
          <div class="flex w-full flex-col items-center justify-center 2xl:w-[72%]">
            <h1 class="heading-small flex w-full justify-start">
              Smart coders grow with JDoodle Pro
            </h1>
            <span v-show="isOwner" class="my-4 flex w-full justify-start"
              >At <span class="mx-1 font-bold">$10</span> USD per month, cancel anytime.
            </span>
            <div v-if="isOwner" class="flex w-full items-start justify-start lg:mr-2">
              <PaymentMethod :id="APICONSTANT.PAYMENT" :plan="PLAN_ENUM.PRO" :isModal="true">
              </PaymentMethod>
            </div>
            <span v-else class="my-4 flex w-full justify-start">
              <FontAwesomeIcon icon="fa-circle-exclamation" class="mr-2 text-xl" />
              Please contact your organization admin to upgrade plan for more AI credits.
            </span>
          </div>
        </div>
      </div>
    </div>
  </ModalLayoutAuth>
</template>
