<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { PROFILEMODELHS } from '@/utils/models'
import { ref, onMounted, watch } from 'vue'

const httpErrorTimeOut = ref<NodeJS.Timeout | number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<NodeJS.Timeout | number | null>(null)
const httpSuccess = ref<string>('')

// const onSubmit = handleSubmit(async (values) => {
//   const requestPayload: IChangeUsernameRequest = {
// displayName: values.displayName
//   }

//   await authService
//     .changeUsername(requestPayload)
//     .then((response: any) => {
//       httpSuccess.value =
//         response?.data?.message || 'Your display name has been changed successfully.'
//       httpError.value = ''
//       resetForm()
//       utilModelsService.closeModal(PROFILEMODELHS.CHANGEUSERNAME)
//     })
//     .catch((error: any) => {
//       httpSuccess.value = ''
//       httpError.value = error?.response?.data?.message
//     })
// })
onMounted(() => {
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Change Display Name" :hs="PROFILEMODELHS.CHANGEUSERNAME">
    <!-- form -->
    <!-- <form @submit.prevent="onSubmit">
      <BaseInput
        label="New Display Name"
        :inputType="'text'"
        placeholder="Joe"
        :error="displayNameError"
        v-model="displayName"
      />

      <p :class="['p-xsmall ', 'error', { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <p :class="['p-xsmall ', 'success', { hidden: !httpSuccess }]">
        {{ httpSuccess }}
      </p>

      <button :disabled="!canSubmit" class="btn-primary btn-rounded-md" type="submit">
        Change Display Name
      </button>
    </form> -->
  </ModelLayout>
</template>
