<script setup lang="ts">
import { RouterLink } from 'vue-router'
import LogoComp from '@/components/utils/LogoComp.vue'
import flagAustralia from '@/assets/images/footer/australia.webp'
import { useIdeStore } from '@/stores/ide.store'
import { computed } from 'vue'
import { useJdroidStore } from '@/stores/jdroid.store'

const ideStore = useIdeStore()
const jdroidStore = useJdroidStore()

const isIdeView = computed(() => {
  return ideStore.isIdeView
})

const isJdroidChatView = computed(() => jdroidStore.isJdroidChatView)

const currentYear = computed(() => {
  return new Date().getFullYear()
})

const isIdeFullScreen = computed(() => {
  return ideStore.isFullScreen
})
</script>

<template>
  <div
    class="section-combined print:hidden"
    :class="[
      {
        'hidden sm:block': isIdeView && !isIdeFullScreen
      },
      {
        hidden: isIdeFullScreen
      },
      { hidden: isJdroidChatView }
    ]"
  >
    <footer class="view-container mx-auto w-full pb-5 pt-14">
      <div class="grid w-full justify-between gap-8 text-sm md:grid-cols-8 lg:grid-cols-9">
        <div
          class="order-last w-full flex-col items-start sm:col-span-1 md:order-none md:col-span-full lg:row-start-1 xl:col-span-3"
        >
          <LogoComp />

          <div class="text-secondary mb-12 mt-4">
            An online programming platform that helps millions of students, teachers and developers
            with tools to learn, teach and practice programming.
          </div>
          <div class="flex gap-7 text-gray-800 dark:text-gray-300">
            <a
              href="https://www.facebook.com/jdoodleide"
              rel="nofollow"
              target="_blank"
              aria-label="Facebook"
            >
              <FontAwesomeIcon :icon="['fab', 'facebook']" class="h-5 w-5" />
            </a>
            <a
              href="https://twitter.com/jdoodleide"
              rel="nofollow"
              target="_blank"
              aria-label="Twitter"
            >
              <FontAwesomeIcon :icon="['fab', 'x-twitter']" class="h-5 w-5" />
            </a>
            <a href="mailto:hello@jdoodle.com" target="_blank" aria-label="Email">
              <FontAwesomeIcon icon="fa-envelope" class="h-5 w-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/jdoodle"
              rel="nofollow"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FontAwesomeIcon :icon="['fab', 'linkedin']" class="h-5 w-5" />
            </a>
            <a
              href="https://www.instagram.com/jdoodleide/"
              rel="nofollow"
              target="_blank"
              aria-label="Instagram"
            >
              <FontAwesomeIcon :icon="['fab', 'instagram']" class="h-5 w-5" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCMg38oKj3kRmzksoRDEAs9Q"
              rel="nofollow"
              target="_blank"
              aria-label="Youtube"
            >
              <FontAwesomeIcon :icon="['fab', 'youtube']" class="h-5 w-5" />
            </a>
          </div>
        </div>
        <div
          class="mt-4 md:col-span-2 md:col-start-1 md:row-start-2 md:mt-0 xl:col-span-1 xl:col-start-5 xl:row-start-1"
        >
          <h4 class="mb-4 text-lg font-semibold">Products</h4>
          <div class="flex flex-col gap-3 font-light">
            <RouterLink :to="{ path: '/integrate-online-ide-compiler-api-plugins' }"
              >Integrate IDE - API & Embed
            </RouterLink>
            <RouterLink :to="{ path: '/online-assessment-teaching' }">Teach & Assess</RouterLink>
          </div>
        </div>
        <div class="mt-4 md:col-span-2 md:mt-0 xl:col-span-1">
          <h4 class="mb-4 text-lg font-semibold">Industry</h4>

          <div class="flex flex-col gap-3 font-light">
            <RouterLink to="/solutions/education-institutions" class="inline-flex"
              >Education</RouterLink
            >
            <RouterLink to="/solutions/education-technology" class="inline-flex"
              >Edu Tech</RouterLink
            >
            <RouterLink to="/solutions/software-library-demostration" class="inline-flex"
              >Software libraries & APIs</RouterLink
            >
          </div>
        </div>
        <div class="mt-4 md:col-span-2 md:mt-0 xl:col-span-1">
          <h4 class="mb-4 text-lg font-semibold">Resources</h4>

          <div class="flex flex-col gap-3 font-light">
            <RouterLink to="/about-us" class="inline-flex">About Us</RouterLink>
            <RouterLink to="/docs" class="inline-flex">Documentation</RouterLink>
            <RouterLink to="/tutorials" class="inline-flex">Tutorials</RouterLink>
            <RouterLink to="/blog" class="inline-flex">Blogs</RouterLink>
            <RouterLink to="/webinar" class="inline-flex">Webinars</RouterLink>
            <RouterLink to="/blog/category/podcast" class="inline-flex">
              Programmable Podcast
            </RouterLink>
          </div>
        </div>
        <div class="mt-4 md:col-span-2 md:mt-0 xl:col-span-1">
          <h4 class="mb-4 text-lg font-semibold">Use Cases</h4>

          <div class="flex flex-col gap-3 font-light">
            <RouterLink to="/case-study/kansas" class="inline-flex">Kansas University</RouterLink>
            <RouterLink to="/case-study/itext" class="inline-flex">iText</RouterLink>
            <RouterLink to="/case-study/linkgroup" class="inline-flex">Link Edu Group</RouterLink>
            <RouterLink to="/case-study/outlier" class="inline-flex">Outlier</RouterLink>
          </div>
        </div>
        <div class="mt-4 md:col-span-2 md:mt-0 xl:col-span-2">
          <h4 class="mb-4 text-lg font-semibold">Get Support</h4>

          <div class="flex flex-col gap-3 font-light">
            <RouterLink to="/contact-us" class="inline-flex">Contact us</RouterLink>
            <a
              class="inline-flex"
              href="https://community.jdoodle.com/c/request-a-feature/"
              target="_blank"
            >
              Make a feature request
            </a>
            <a class="inline-flex" href="https://community.jdoodle.com/" target="_blank">
              JDoodle community
            </a>
            <!-- <div>
              Stay up to date

              <div class="relative">
                <input
                  placeholder="Your email address"
                  class="mt-4 w-full rounded-md bg-neutral-200 px-2.5 py-2 text-xs bg-gray-100 dark:bg-neutral-800"
                />
                <FontAwesomeIcon
                  icon="fa-paper-plane"
                  class="pointer-events-none absolute right-3 top-6 h-4 w-4 text-gray-400 block md:hidden lg:block"
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="mt-8 border-t pb-6 pt-10 text-sm">
        <div class="gap-4 md:grid md:grid-cols-8 lg:flex-row lg:items-center lg:justify-between">
          <p class="text-secondary col-span-4">
            © 2013-{{ currentYear }} Nutpan Pty Ltd. All Rights Reserved
          </p>

          <div class="col-span-2 flex flex-row gap-10 py-5 md:justify-center md:py-0">
            <RouterLink :to="{ path: '/terms' }"> Terms of Service </RouterLink>
            <RouterLink :to="{ path: '/privacy' }"> Privacy </RouterLink>
          </div>

          <div class="col-span-2 flex text-center md:justify-end">
            <div>
              <span>Made with </span>
              <FontAwesomeIcon icon="fa-heart" class="mx-1 h-4 w-4 text-text-brand" />
              <span class=""> in Australia</span>
            </div>
            <div class="ml-3 h-4">
              <img v-lazy="flagAustralia" alt="australia" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
