<script setup lang="ts">
import { onMounted, ref } from 'vue'

const showBanner = ref<Boolean>(true)

/**
 * Hide webinar banner
 */
const hideBanner = () => {
  const currentDate = new Date()
  setCookie('bannerExpirationTime', currentDate.getTime().toString(), 1)
  showBanner.value = false
}
/**
 * Get the cookie value banner expiration time
 * @param name Key of the cookie
 * @returns Cookie value
 */
const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 ? parts.pop()?.split(';').shift() : undefined
}
/**
 * Set the cookie value with banner expiration time
 * @param name Key of the cookie
 * @param value Value of the cookie
 * @param expires Number of days after which banner will expire
 */
const setCookie = (name: string, value: string, expires: number): void => {
  const d = new Date()
  d.setTime(d.getTime() + (expires || 0) * 24 * 60 * 60 * 1000)

  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${d.toUTCString()}; path=/`
}
/**
 * Remove the cookie value (Set the time in past)
 * @param name Key of the cookie
 */
const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
}

onMounted(() => {
  const bannerExpirationTime = getCookie('bannerExpirationTime')

  if (bannerExpirationTime) {
    const nextDayTimestamp = Number(bannerExpirationTime) + 24 * 60 * 60 * 1000

    if (Date.now() < nextDayTimestamp) {
      showBanner.value = false
    } else {
      showBanner.value = true
      deleteCookie('bannerExpirationTime')
    }
  }
})
</script>

<template>
  <div
    v-if="showBanner"
    class="section-primary relative flex justify-center border-b-[1px] p-4 text-center dark:border-t-[1px] dark:border-[#1C4875] dark:bg-[#0A2540]"
  >
    <div class="text-md mt-3 px-4 font-semibold sm:mt-0 lg:text-lg">
      <span
        >Build applications on the go with JDoodle Mobile App.
        <span class="text-brand">Live Webinar</span> | 8th May at 2:30 pm (AEST) <span> </span
      ></span>
      <button class="my-2 text-sm lg:text-base xl:my-0">
        <a
          href="https://jdoodle.clickmeeting.com/build-applications-with-jdoodle-mobile-app/register"
          class="btn-primary ml-4 rounded-md px-3 py-2"
          target="_blank"
          >Book Your Seat</a
        >
      </button>
    </div>
    <div class="absolute right-0 top-0 p-1 lg:top-[15px] lg:pr-3">
      <button data-cy="close-banner">
        <FontAwesomeIcon
          icon="fa-xmark"
          class="text-brand h-5 w-5 dark:text-white"
          @click="hideBanner"
        />
      </button>
    </div>
  </div>
</template>
