<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { AUTHMODELHS } from '@/utils/models'
import authService from '@/services/auth.service'
import utilModelsService from '@/services/util.models.service'
import { handleError } from '@/services/errorHandler.service'
import LoginRegisterBanner from '@/components/header/auth/AuthOnboardingAdvBanner/LoginRegisterBanner.vue'
import Button from '@/components/atoms/button/index.vue'
import ModelLayoutAuth from '@/layouts/ModelLayoutAuth.vue'
import { useOrganisationStore } from '@/stores/organisation.store'

import roundCircle from '@/assets/images/svg/AuthOnboarding/Ellipse522.svg'
import { INVITE_RESPONSE } from '@/components/organisation/enum/organisation.enums'
import { createOwnAccount, respondToInvite } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'

const router = useRouter()
const organisationStore = useOrganisationStore()
const isLoading = ref<boolean>(false)
const httpError = ref<string>('')
const invitations = computed(() => {
  return organisationStore.userInvitations
})

/**
 * Handles the user's response to an invitation.
 * @param currentResponse response
 * @param invitationToken token
 */
const handleRespondToInvite = async (currentResponse: INVITE_RESPONSE, invitationToken: string) => {
  httpError.value = ''
  isLoading.value = true

  respondToInvite(currentResponse, invitationToken)
    .then(async (res) => {
      if (res.status == HttpStatusCode.Ok) {
        await utilModelsService.closeModalAsync(AUTHMODELHS.INVITEACCEPT).then(async () => {
          utilModelsService.closeModal(AUTHMODELHS.ONBOARDING)
          await authService.initAuth(false, false)
        })
        httpError.value = ''
      }
    })
    .catch((error: any) => {
      httpError.value = handleError(error)
    })
    .finally(() => {
      isLoading.value = false
    })
}
/**
 * close model and redirect to org page
 */
const handleCreateAccount = async () => {
  httpError.value = ''
  isLoading.value = true
  createOwnAccount()
    .then(async () => {
      await authService.initAuth(false, true, false)
      utilModelsService.closeModal(AUTHMODELHS.INVITEACCEPT)
      router.push({ name: 'home' })
      httpError.value = ''
    })
    .catch((error: any) => {
      httpError.value = handleError(error)
    })
    .finally(() => {
      isLoading.value = false
    })
}
</script>

<template>
  <ModelLayoutAuth :hs="AUTHMODELHS.INVITEACCEPT">
    <div class="flex h-screen w-full flex-col overflow-hidden">
      <div class="flex w-full flex-1 gap-10 p-1 sm:p-5">
        <div class="hidden w-full rounded-3xl bg-[#EBEFF6] md:hidden lg:block lg:min-w-[50%]">
          <LoginRegisterBanner
            text="A feature-rich coding suite powered by AI"
            highlighertext="powered by AI"
          />
        </div>

        <div class="h-full w-full rounded-md p-10 lg:min-w-[40%]">
          <div class="flex flex-col items-center justify-center">
            <div class="flex w-full flex-col justify-start gap-4">
              <div class="new-secondary flex w-full items-start text-3xl">
                You have team invites
              </div>
              <div
                v-if="invitations && invitations.length > 0"
                class="new-secondary-700 flex w-full items-start"
              >
                You can accept only one invite to join a team.
              </div>

              <div v-else class="new-secondary-700 flex w-full items-start">
                Accept the invite to join the team.
              </div>
            </div>

            <div class="mt-10 flex w-full flex-col items-start justify-start gap-2">
              <div class="flex h-full w-full" v-for="invite in invitations" :key="invite.token">
                <div
                  className="max-w-full h-auto sm:h-20 w-full flex sm:flex-row flex-col gap-5 px-3 py-2 rounded-md border border-slate-400 justify-between items-center"
                >
                  <div className="h-auto justify-start items-center gap-4 flex">
                    <div className="justify-start items-center gap-4 flex">
                      <div className="justify-start items-center gap-2.5 flex">
                        <div className="w-14 h-14 relative">
                          <div
                            className="w-[60px] h-[60px] left-0 top-0 absolute bg-orange-300 rounded-full"
                          />
                          <img
                            className="w-12 h-12 left-1 top-1 absolute rounded-full"
                            src="https://via.placeholder.com/50x50"
                          />
                          <img
                            className="w-[50px] h-[50px] left-[5px] top-[5px] absolute rounded-full"
                            :src="roundCircle"
                          />
                        </div>
                        <div className="flex-col justify-start items-start inline-flex">
                          <div className="justify-start items-center inline-flex">
                            <div className="justify-center items-center flex">
                              <div className="text-sky-950 text-base font-semibold leading-normal">
                                {{ invite.ownerFirstName }}&nbsp;&nbsp;{{ invite.ownerLastName }}
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-center inline-flex">
                            <div className="text-xs font-medium  leading-normal">
                              {{ invite.ownerUsername }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-4 flex">
                    <Button
                      :disabled="isLoading"
                      class="w-full py-2"
                      variant="filled"
                      size="medium"
                      @click="handleRespondToInvite(INVITE_RESPONSE.ACCEPT, invite.token)"
                    >
                      Accept Invite
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="invitations && invitations.length > 0"
              class="p-xsmall flex items-center p-4 py-4 text-sm text-text-tertiary before:mr-6 before:flex-[1_1_0%] before:border before:border-neutral-100 after:ml-6 after:flex-[1_1_0%] after:border after:border-neutral-100"
            >
              or
            </div>

            <div class="flex h-full w-full">
              <div className="max-w-full h-20 w-full flex flex-row justify-between items-center">
                <div className="flex-col justify-start items-start inline-flex gap-2">
                  <div className="justify-start items-center inline-flex">
                    <div className="justify-center items-center flex">
                      <div className="text-lg font-semibold leading-normal">
                        Create my personal account
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-center inline-flex">
                    <div class="new-secondary-700 flex w-full items-start">
                      Ignore all invites and setup your account
                    </div>
                  </div>
                </div>

                <div className="justify-start items-start flex">
                  <div className="w-full h-10  rounded justify-end items-center flex">
                    <div className=" justify-center items-center gap-2.5 flex">
                      <Button
                        size="medium"
                        color="secondary"
                        variant="outline"
                        @click="handleCreateAccount"
                      >
                        Create my account
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isLoading" class="text-primary mx-3 mt-2.5 text-sm">Loading...</div>
            <p
              :class="[
                'p-small mt-2',
                'text-center',
                { hidden: !httpError && isLoading, 'text-sky-950': isLoading, error: !isLoading }
              ]"
            >
              {{ httpError }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- form -->
  </ModelLayoutAuth>
</template>
